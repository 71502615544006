import React from 'react';
import { shape, arrayOf, string, func } from 'prop-types';
import IconFactory from '../icons/factory-builder';

const RelatedMobile = ({ label, items, namespace, runCatchErrorBoundary }) => {
  try {
    const listItems = items.map(item => (
      <li className={`${namespace}__item`} key={item.id}>
        <a className={`${namespace}__link`} href={item.href}>
          <span className={`${namespace}__link-icon ${namespace}__link-column`}>
            {IconFactory({ id: 'search', color: 'GRAY' })}
          </span>
          <p className={`${namespace}__link-text ${namespace}__link-column`}>{item.text}</p>
        </a>
      </li>
    ));

    return (
      <>
        <h2 className={`${namespace}__title`}>{label}</h2>
        <ul className={`${namespace}__list`}>{listItems}</ul>
      </>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

RelatedMobile.propTypes = {
  namespace: string,
  items: arrayOf(
    shape({
      href: string.isRequired,
      id: string.isRequired,
      text: string.isRequired,
    }),
  ).isRequired,
  label: string.isRequired,
  runCatchErrorBoundary: func,
};

RelatedMobile.defaultProps = {
  namespace: null,
  className: null,
  runCatchErrorBoundary: () => {},
};

export default RelatedMobile;
