import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import classNames from 'classnames';

const RelatedDesktop = ({ label, items, namespace, runCatchErrorBoundary }) => {
  try {
    const listItems = items.map(item => (
      <li className={`${namespace}__item`} key={item.id}>
        <a className={`${namespace}__link`} href={item.href}>
          {item.text}
        </a>
      </li>
    ));

    return (
      <ul className={`${namespace}__list`}>
        <li className={classNames(`${namespace}__item`, `${namespace}__item-title`)}>{label}</li>
        {listItems}
      </ul>
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

RelatedDesktop.propTypes = {
  namespace: string,
  items: arrayOf(
    shape({
      href: string.isRequired,
      id: string.isRequired,
      text: string.isRequired,
    }),
  ).isRequired,
  label: string.isRequired,
  runCatchErrorBoundary: func,
};

RelatedDesktop.defaultProps = {
  namespace: null,
  className: null,
  runCatchErrorBoundary: () => {},
};

export default RelatedDesktop;
