import React, { useContext } from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import classNames from 'classnames';
import StaticPropsContext from '../context/static-props';
import RelatedDesktop from './related.desktop';
import RelatedMobile from './related.mobile';

const RELATED_DEVICE_MOBILE = 'mobile';

const RelatedWrapper = ({ label, items, className, namespace, runCatchErrorBoundary }) => {
  const { deviceType } = useContext(StaticPropsContext);
  const classes = classNames(namespace, className);

  try {
    const getRelatedMarkup = () => {
      switch (deviceType) {
        case RELATED_DEVICE_MOBILE:
          return (
            <RelatedMobile
              label={label}
              items={items}
              namespace={namespace}
              runCatchErrorBoundary={runCatchErrorBoundary}
            />
          );
        default:
          return (
            <RelatedDesktop
              label={label}
              items={items}
              namespace={namespace}
              runCatchErrorBoundary={runCatchErrorBoundary}
            />
          );
      }
    };

    const relatedMarkup = getRelatedMarkup();

    return <div className={classes}>{relatedMarkup}</div>;
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

RelatedWrapper.propTypes = {
  namespace: string,
  className: string,
  items: arrayOf(
    shape({
      href: string.isRequired,
      id: string.isRequired,
      text: string.isRequired,
    }),
  ).isRequired,
  label: string.isRequired,
  runCatchErrorBoundary: func,
};

RelatedWrapper.defaultProps = {
  namespace: null,
  className: null,
  runCatchErrorBoundary: () => {},
};

export default RelatedWrapper;
